import React from 'react';

import './SubCatTable.css';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';

export default function SubCatTable(props) {
    const { title, data, description } = props;

    function formatSubCatName(name){
        const splitName = name.split("*");
        if (splitName.length === 1) {
            return(
                <div>{name}</div>
            );
        }
        return (
            <div>
                <div>{splitName[0]}</div>
                <div className="subcat-parent">{splitName[1]}</div>
            </div>
        );
    }

    return (
        <div className="subcat-table column-medium row-large">
            <div className="table-header">
                <span className="table-title">{title}</span>
                <span className="table-description">{description}</span>
            </div>
            <TableContainer component={Card}>
              <Table className="stable" size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Category</TableCell>
                    <TableCell align="right">Average Price (USD)</TableCell>
                    <TableCell align="right">YoY Change</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map(row => {
                    const diff = parseFloat((row[3] * 100).toFixed(0));
                    const arrow = diff > 0 ? String.fromCharCode(8593) : String.fromCharCode(8595)
                    return (
                        <TableRow key={row[0]}>
                          <TableCell component="th" scope="row">
                            {formatSubCatName(row[0])}
                          </TableCell>
                          <TableCell align="right">{`$${row[1].toFixed(0).toLocaleString()}`}</TableCell>
                          <TableCell align="right">
                            <div>
                                <div>{`${diff.toLocaleString()}% ${arrow}`}</div>
                            </div>
                        </TableCell>
                        </TableRow>
                  )})}
                </TableBody>
              </Table>
            </TableContainer>
        </div>
    );
}
