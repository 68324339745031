const YEARS = ['2024', '2023', '2022'];

function getMatchingPivots(
  data,
  dataType,
  interval = 'week',
  region = 'ALL',
  category = 'ALL',
  tier = 'ALL'
) {
  const dataByInterval = data[dataType][interval];
  return dataByInterval.map((dataByYear) => {
    return dataByYear.filter((entry, index) => {
      const tierMatch = tier !== 'ALL' ? tier.split(' OR ') : tier;
      return (
        (region !== 'ALL' ? entry['pivots']['market'] === region : true) &&
        (category !== 'ALL' ? entry['pivots']['cs_grp'] === category : true) &&
        (tier !== 'ALL' ? tierMatch.includes(entry['pivots']['tier']) : true)
      );
    });
  });
}

function _weightedAverage(data) {
  const weightedSum = data.reduce((sum, b) => sum + b.docs * b.value, 0);
  const sumWeights = data.reduce((sum, b) => sum + b.docs, 0);
  return {
    value: weightedSum / sumWeights,
    docs: sumWeights,
  };
}

function aggregateData(allData, dataKey, interval) {
  const dateLength = interval === 'month' ? 12 : 52;
  const xValues = allData[1]
    .find((pivotGroups) => pivotGroups.data.length >= dateLength)
    .data.map((d) => d[0]);
  const aggData = allData.map((dataByYear) => {
    const dataByDate = dataByYear.reduce((aggregate, current, j) => {
      const { data, docs } = current;
      data.map((date, index) => {
        const value = date[1][dataKey];
        if (value != null) {
          const payload = {
            value,
            docs,
          };
          if (aggregate[index] == null) {
            aggregate[index] = [payload];
          } else {
            aggregate[index].push(payload);
          }
        }
        return aggregate;
      });
      return aggregate;
    }, {});

    let aggregatedData = {};
    Object.keys(dataByDate).forEach((key) => {
      if (dataKey === 'new') {
        aggregatedData[key] = dataByDate[key].reduce((sum, b) => sum + b.value, 0);
      } else {
        aggregatedData[key] = _weightedAverage(dataByDate[key]).value;
      }
    }, {});
    return aggregatedData;
  });

  return {
    data: aggData,
    xValues,
  };
}

function formatData(aggregatedData) {
  const { xValues, data } = aggregatedData;
  const formattedData = YEARS.map((yLabel, index) => {
    const yearData = data[index];
    const series = Object.keys(yearData)
      .slice(0, xValues.length)
      .map((index) => {
        return {
          x: xValues[index],
          y: yearData[index],
        };
      });
    return {
      id: yLabel,
      data: series,
    };
  });
  formattedData.reverse();
  return formattedData;
}

export function transformData(
  allData,
  dataType,
  dataKey,
  interval,
  region = 'ALL',
  category = 'ALL',
  tier = 'ALL'
) {
  const matchingData = getMatchingPivots(allData, dataType, interval, region, category, tier);
  const aggregatedData = aggregateData(matchingData, dataKey, interval);
  return formatData(aggregatedData);
}

export function getImpactData(data) {
  // If we have 3 years of data, then this year is 2022
  const thisYear = data.length === 3 ? '2024' : '2023';
  const previousYear = data.length === 3 ? '2023' : '2022';

  // Get the data from this year and last year
  const dataThisYear = data.find((datum) => datum.id === thisYear).data;
  const dataLastYear = data.find((datum) => datum.id === previousYear).data;

  // Find last non-null data from this year (this is current data point)
  const mostRecentData = dataThisYear.filter((datum) => datum.y != null).slice(-1)[0];

  // No data yet for this year
  if (mostRecentData == null) {
    return {
      today: null,
      percentage: 0,
    };
  }

  const lastYear = dataLastYear.find((datum) => datum.x === mostRecentData.x);
  const displayValue =
    mostRecentData.y < 1
      ? (mostRecentData.y * 100).toFixed(0)
      : formatLargeNumbers(mostRecentData.y);

  const percentage = ((mostRecentData.y - lastYear.y) / lastYear.y) * 100;
  return {
    today: displayValue,
    percentage: parseFloat(percentage.toFixed(0)),
  };
}

function getYValues(data) {
  return data.map((d) => d.y);
}

export function getMinMaxDiscount(discountData, proportionData) {
  const allData = getYValues(
    discountData[0].data
      .concat(proportionData[0].data)
      .concat(discountData[1].data)
      .concat(proportionData[1].data)
  );
  return {
    max: parseFloat(Math.max(...allData).toFixed(1)) + 0.05,
    min: parseFloat(Math.min(...allData).toFixed(1)) - 0.05,
  };
}

export function formatLargeNumbers(number) {
  let n = number;
  let symbol = '';
  if (number >= 1000000) {
    n = number / 1000000;
    symbol = 'M';
  } else if (number >= 1000) {
    n = number / 1000;
    symbol = 'K';
  }
  return `${n.toLocaleString(undefined, { maximumFractionDigits: 1 })}${symbol}`;
}

function aggregateTableData(allData) {
  return allData.map((dataByYear) => {
    const dataBySubCat = dataByYear.reduce((aggregate, current, j) => {
      const { data, docs } = current;
      const subCat = current['pivots']['product_searches'];
      if (data.length > 0) {
        const value = data[0][1]['pricing_stats'];
        const payload = {
          value,
          docs,
        };
        if (aggregate[subCat] == null) {
          aggregate[subCat] = [payload];
        } else {
          aggregate[subCat].push(payload);
        }
      }
      return aggregate;
    }, {});

    let aggregatedData = {};
    Object.keys(dataBySubCat).forEach((key) => {
      aggregatedData[key] = _weightedAverage(dataBySubCat[key]);
    }, {});
    return aggregatedData;
  });
}

export function transformTableData(
  allData,
  interval,
  region = 'ALL',
  category = 'ALL',
  tier = 'ALL',
  topN = 5,
  maxDiff = 4
) {
  const matchingData = getMatchingPivots(
    allData,
    'average_pricing',
    interval,
    region,
    category,
    tier
  );

  const minCount = interval === 'week' ? 100 : 500;
  const aggregatedData = aggregateTableData(matchingData);
  const currentData = aggregatedData[0];
  const yearData = aggregatedData[1];

  const diffs = Object.keys(currentData).map((subCat) => {
    const { value, docs } = currentData[subCat];
    const yearValue = yearData[subCat]?.value;
    const yearDocs = yearData[subCat]?.docs;
    let diff;
    if (value && yearValue && docs >= minCount && yearDocs >= minCount) {
      diff = (value - yearValue) / yearValue;
    } else {
      diff = 0;
    }

    if (diff > maxDiff) {
      diff = 0;
    }
    return [subCat, value, yearValue, diff];
  });

  diffs.sort((a, b) => (Math.abs(a[3]) > Math.abs(b[3]) ? -1 : 1));

  return diffs.slice(0, topN);
}
