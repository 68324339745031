import React from 'react';
import ReactGA from 'react-ga';

import './Container.css';

import LineChart from './charts/LineChart'
import { Interval, Region, Market, Category } from './components/Header.jsx';
import Impact from './components/Impact.jsx';
import SubCatTable from './components/SubCatTable.jsx';
import fullData from './data.json'

import { transformData, getImpactData, getMinMaxDiscount, transformTableData, formatLargeNumbers } from './util.js'

function Container() {
    const [region, setRegion] = React.useState('ALL');
    const [tier, setTier] = React.useState('ALL');
    const [category, setCategory] = React.useState('ALL');
    const [interval, setDataInterval] = React.useState('month');

    React.useEffect(() => {
        ReactGA.event({
            category: "Navigation",
            action: `User loaded region ${region}`,
        });
    }, [region])

    React.useEffect(() => {
        ReactGA.event({
            category: "Navigation",
            action: `User viewed interval ${interval}`,
        });
    }, [interval])

    const onLinkClick = link => {
        ReactGA.event({
            category: "Navigation",
            action: `User navigated to ${link}`,
        });
    }

    const newIn = transformData(fullData, "new_in", "new", interval, region, category, tier)
    const discountAvg = transformData(fullData, "discount", "discount_percentage__stats", interval, region, category, tier)
    const discountProportion = transformData(fullData, "discount", "fraction_discounted", interval, region, category, tier)
    const tableData = transformTableData(fullData, interval, region, category, tier)

    const newInImpact = getImpactData(newIn)
    const discountAvgImpact = getImpactData(discountAvg)
    const discountProportionImpact = getImpactData(discountProportion)

    const yDiscountScale = getMinMaxDiscount(discountAvg, discountProportion)

    return (
        <div className="container">
            <div className="grid-container">
                <div className="titleContainer">
                    <div className="pageTitle">The EDITED Retail Dashboard</div>
                    <div className="pageSubtitle">Create your own market view by selecting specific options from the dropdown menus</div>
                </div>
                <div className="controls-container">
                    <Interval interval={interval} setDataInterval={setDataInterval} />
                    <Region region={region} setRegion={setRegion}  />
                    <Category category={category} setCategory={setCategory}  />
                    <Market tier={tier} setTier={setTier}  />
                </div>
                <div className="card column-large row-large">
                    <div className="graph-header">
                        <span className="graph-title">New Arrivals</span>
                        <span className="graph-description"> Number of new styles entering the market</span>
                    </div>
                    <LineChart data={newIn} yaxis="New In" yformat={formatLargeNumbers} yScale={{min: 0, max: "auto"}} />
                </div>
                <Impact title="New In" subtitle={`${interval === 'month' ? 'This Month' : 'This Week'}`} number={newInImpact.today} percentage={newInImpact.percentage}/>
                <Impact title="Avg Discount" subtitle={`${interval === 'month' ? 'This Month' : 'This Week' }`} number={`${discountAvgImpact.today}%`} percentage={discountAvgImpact.percentage}/>
                <Impact title="Avg Assortment Discount" subtitle={`${interval === 'month' ? 'This Month' : 'This Week' }`} number={`${discountProportionImpact.today}%`} percentage={discountProportionImpact.percentage}/>

                <SubCatTable
                    title="Categories with Largest Change in Price"
                    description="Comparing Average Price Year on Year"
                    data={tableData}
                />
                <div className="card column-large row-large">
                    <div className="graph-header">
                        <span className="graph-title"> Average Product Discount</span>
                        <span className="graph-description">Mean of discounted products across market</span>
                    </div>
                    <LineChart data={discountAvg} yaxis="Avg Discount" yformat=".0%" yScale={yDiscountScale} />
                </div>
                <div className="card column-large row-large">
                    <div className="graph-header">
                        <span className="graph-title">% Assortment On Discount</span>
                        <span className="graph-description">% share of assortment on markdown</span>
                    </div>
                    <LineChart data={discountProportion} yaxis="Discounted Assortment" yformat=".0%" yScale={yDiscountScale}/>
                </div>
                <div className="card insights row-small" >
                    <div className="insights-header">
                        <span className="graph-title">Overview</span>
                    </div>
                    <div className="insights-content">
                        Powered by real-time data from the EDITED Retail Intelligence Platform, this dashboard tracks key metrics relating to products from a sample of the world’s leading retailers across selected regions. Retailers can understand the state of major markets, regions, and categories to start reacting according to data and strategize their next steps.
                        <div className="footer">
                            <a className="button" style={{ maxWidth: 150, marginRight: 15 }} onClick={() => onLinkClick("Book demo")} href="https://edited.com/book-a-demo/" target="_blank"  rel="noopener noreferrer">See how it works</a>
                            <a onClick={() => onLinkClick("FAQ")} href="https://edited.com/glossary/the-edited-covid-19-retail-analysis-dashboard-faq/" target="_blank"  rel="noopener noreferrer">FAQ</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Container;
