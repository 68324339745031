import React from 'react';
import './Impact.css';

export default function Impact(props) {
    const diffSymbol = props.percentage > 0 ? "+" : "-";
    const numberIsNull = props.number == null || props.number === "null%";
    const number =  numberIsNull ? "Not enough data" : props.number;
    const numberClass = numberIsNull ? "not-enough-data" : "number";
    const percentageClass = numberIsNull ? "none" : "percentage";
    return (
        <div className="row-small impact">
            <div className="title"> {props.title} </div>
            <div className="subtitle"> {props.subtitle} </div>
            <div className="numberContainer">
              <div className={numberClass}> {number} </div>
              <div className={percentageClass}> {diffSymbol}{Math.abs(props.percentage).toLocaleString()}% </div>
            </div>
        </div>
    )
}
