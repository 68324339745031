import React from 'react';
import './Header.css';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export const Interval = props => {
    const { interval, setDataInterval } = props;

    const handleIntervalChange = event => {
      setDataInterval(event.currentTarget.value)
    }

    const toggleChildren = [
      <ToggleButton key={1} value="week">
        Week
      </ToggleButton>,
      <ToggleButton key={2} value="month">
        Month
      </ToggleButton>
    ];

    return (
        <div className="interval-selector">
            <div className="label" style={{ margin: "0 10px 0 0", textAlign: "right", fontSize: "12px" }}>
                Data Controls
            </div>
            <ToggleButtonGroup
                className="selector"
                value={interval}
                exclusive
                onChange={handleIntervalChange}
            >
                {toggleChildren}
            </ToggleButtonGroup>
        </div>
    )
}

export const Region = props => {
    const { region, setRegion } = props;
    const handleChange = event => {
        setRegion(event.target.value);
    }

    return (
        <div className="region-selector">
            <Select
                className="selector"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={region}
                onChange={handleChange}
            >
              <MenuItem value={"ALL"} style={{ fontWeight: 'bold' }}>All Regions</MenuItem>
              <MenuItem value={"AU"}>Australia</MenuItem>
              <MenuItem value={"CN"}>China</MenuItem>
              <MenuItem value={"FR"}>France</MenuItem>
              <MenuItem value={"DE"}>Germany</MenuItem>
              <MenuItem value={"IT"}>Italy</MenuItem>
              <MenuItem value={"JP"}>Japan</MenuItem>
              <MenuItem value={"KR"}>South Korea</MenuItem>
              <MenuItem value={"ES"}>Spain</MenuItem>
              <MenuItem value={"SE"}>Sweden</MenuItem>
              <MenuItem value={"UK"}>United Kingdom</MenuItem>
              <MenuItem value={"US"}>United States</MenuItem>
            </Select>
        </div>

    )
}

export const Market = props => {
    const { tier, setTier } = props;
    const handleChange = event => {
        setTier(event.target.value);
    }

    return (
        <div className="region-selector">
            <Select
                className="selector"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tier}
                onChange={handleChange}
            >
              <MenuItem value={"ALL"} style={{ fontWeight: 'bold' }}>All Markets</MenuItem>
              <MenuItem value={"value OR mass"}>Mass</MenuItem>
              <MenuItem value={"luxury OR premium"}>Luxury</MenuItem>
            </Select>
        </div>
    )
}

export const Category = props => {
    const { category, setCategory } = props;
    const handleChange = event => {
        setCategory(event.target.value);
    }

    return (
        <div className="region-selector">
            <Select
                className="selector"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                onChange={handleChange}
            >
                <MenuItem value={"ALL"} style={{ fontWeight: 'bold' }}>All Categories</MenuItem>
                <MenuItem value={"accessories"}>Accessories</MenuItem>
                <MenuItem value={"bottoms"}>Bottoms</MenuItem>
                <MenuItem value={"dresses"}>Dresses</MenuItem>
                <MenuItem value={"footwear"}>Footwear</MenuItem>
                <MenuItem value={"nightwear"}>Nightwear</MenuItem>
                <MenuItem value={"outerwear"}>Outerwear</MenuItem>
                <MenuItem value={"swim"}>Swimwear</MenuItem>
                <MenuItem value={"tops"}>Tops</MenuItem>
                <MenuItem value={"underwear"}>Underwear</MenuItem>
            </Select>
        </div>
    )
}
