// import * as d3 from "d3";
import React from 'react';
import { Line } from '@nivo/line';
import { AutoSizer } from 'react-virtualized';

// const parseTime = d3.timeParse("%Y-%m-%d");
const isMobile = window.matchMedia('(max-width: 600px)').matches;
const styleSheet = {
  gridColor: {
    width: 10,
    height: 10,
  },
  gridNumber: {
    textAlign: 'right',
    fontWeight: 'bold',
  },
};

// function generateMarkers(markers) {
//     return markers.map((data) => {
//         return {
//             axis: 'x',
//             value: parseTime(data[1]),
//             lineStyle: { strokeWidth: 1, "stroke-dasharray": ("6, 6") },
//             legend: data[0].toLocaleString() + ' Confirmed Cases',
//             legendOrientation: 'vertical',
//             textStyle: { fontSize: 10 }
//         }
//     });
// }

const CHART_COLOURS = ['#3F9B89', '#1795D6', '#F1B51D'];

export default function LineChart(props) {
  const { data, yaxis, yformat, yScale = { min: 'auto', max: 'auto' } } = props;

  function getTooltipDifference(currentDataPoint, nextDataPoint) {
    if (!nextDataPoint) return '';
    const diff = (
      (100 * (currentDataPoint.data.y - nextDataPoint.data.y)) /
      nextDataPoint.data.y
    ).toFixed(0);
    return `${diff > 0 ? String.fromCharCode(8593) : String.fromCharCode(8595)}${Math.abs(
      diff
    )}% YoY`;
  }

  function getToolTipData(currentDataPoint) {
    if (yformat === '.0%') {
      return `${(currentDataPoint.data.y * 100).toFixed(0)}%`;
    }
    return `${currentDataPoint.data.y.toLocaleString()}`;
  }

  function toolTip(params) {
    const points = params.slice.points;
    return (
      <div
        style={{
          position: 'absolute',
          background: 'white',
          display: 'grid',
          width: 180,
          gridTemplateColumns: '10% auto auto auto',
          gridColumnGap: '3px',
          textAlign: 'left',
          padding: '3px 6px',
          border: '1px solid #ccc',
          fontSize: 10,
        }}
      >
        {points.map((point, index, points) => (
          <>
            <div style={{ ...styleSheet['gridColor'], background: point.serieColor }} />
            <div>{point.serieId}:</div>
            <div style={styleSheet['gridNumber']}>
              {getToolTipData(point, points[index + 1] ? points[index + 1] : null)}
            </div>
            <div style={styleSheet['gridNumber']}>
              {getTooltipDifference(point, points[index + 1] ? points[index + 1] : null)}
            </div>
          </>
        ))}
      </div>
    );
  }

  const styleById = {
    2019: {
      strokeWidth: 3,
    },
    default: {
      strokeWidth: 2,
    },
  };

  const DashedLine = ({ series, lineGenerator, xScale, yScale }) => {
    return series.map(({ id, data, color }) => (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y),
          }))
        )}
        fill="none"
        stroke={color}
        style={styleById[id] || styleById.default}
      />
    ));
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Line
          data={data}
          height={height}
          width={width}
          colors={CHART_COLOURS}
          margin={{ top: 10, right: 20, bottom: 65, left: 65 }}
          xScale={{
            type: 'time',
            format: '%Y-%m-%d',
            precision: 'day',
            useUTC: false,
          }}
          axisBottom={{
            format: '%b %d',
            orient: 'bottom',
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{ type: 'linear', stacked: false, max: yScale.max, min: yScale.min }}
          axisLeft={{
            format: yformat,
            legend: yaxis,
            tickValues: yformat === '.0%' ? 5 : 8,
            legendOffset: -60,
            orient: 'left',
            legendPosition: 'middle',
          }}
          curve="monotoneX"
          pointSize={isMobile ? 0 : 3}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          isInteractive={true}
          useMesh={true}
          enableSlices="x"
          sliceTooltip={toolTip}
          legends={[
            {
              anchor: 'top-right',
              direction: 'row',
              translateX: 15,
              translateY: -32,
              itemWidth: 60,
              itemHeight: 15,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
            },
          ]}
          animate={false}
          layers={[
            'grid',
            'markers',
            'axes',
            'areas',
            'crosshair',
            DashedLine,
            'points',
            'slices',
            'mesh',
            'legends',
          ]}
        />
      )}
    </AutoSizer>
  );
}
